// Libs
import PubSub from 'vanilla-pubsub'

// Utils
import { setUA, getParsedUA } from '@/assets/js/utils'

// Plugins
import WebfontLoader from './plugins/webFontLoader'

// ProjectClass (singleton)
import project from './project'

// Modules
import Viewport from './modules/Viewport'
import HashPosition from './modules/HashPosition'
import Header from './modules/Header'
import Menu from './modules/Menu'
import DisableScroll from './modules/DisableScroll'
import SmoothScroll from './modules/SmoothScroll'

// Types
import type { Modules, ChunkMap } from '@/assets/js/types/app'

class App {
  pages: ChunkMap
  globals: Modules

  constructor() {
    this._initialize()

    // ページごとに実行されるjs群 （for dynamic import）
    this.pages = {
      top: 'top.js',
      archives: 'archives.js',
      post: 'post.js',
    }

    // ページ共通で実行されるjs群
    this.globals = {
      viewport: new Viewport(),
      hashPosition: new HashPosition(),
      header: new Header('.js-header'),
      menu: new Menu('.js-menuTrigger'),
      disableScroll: new DisableScroll(),
      smoothScroll: new SmoothScroll(),
    }

    this._setup()
  }

  _initialize() {
    // 使用ブラウザをクラス名として追加
    // setUA(navigator.userAgent)
    // const results = getParsedUA()

    // if (/safari/i.test(results?.browser?.name || '')) {
    //   document.documentElement.classList.add('safari')

    //   if (/ios/i.test(results?.os?.name || '')) {
    //     document.documentElement.classList.add('ios')
    //   }

    //   if (/^14\.0/.test(results?.browser?.version || '')) {
    //     document.documentElement.classList.add('legacy')
    //     document.documentElement.classList.add('flex-gap-not-supported')
    //   }
    // }
    // const browserName = getBrowser()
    // document.documentElement.classList.add(browserName)

    // Webフォント読み込み
    new WebfontLoader()
  }

  async _setup() {
    // 現在ページを取得
    const $root: HTMLElement =
      document.querySelector('.js-root') || document.querySelector('body')!
    const current = $root.dataset.page || ''

    // 対応するDynamic Importモジュールを確認
    const page = this.pages[current] || false

    // グローバルモジュールをprojectに追加
    project.addModules(this.globals)

    // 現在ページ用のmodule郡をDynamic Importしてインスタンス化
    if (page) {
      await this.importer(current)
    }

    PubSub.publish('App.ready')
    document.body.classList.add('is-app-ready')
  }

  importer(filename: string) {
    return import(`./pages/${filename}`)
      .then((Module) => new Module.default('.js-root'))
      .catch((err) => {
        console.error(err)
      })
  }
}

new App()
