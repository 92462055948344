import PubSub from 'vanilla-pubsub'
import type { WindowState } from '@/assets/js/project'

export default class {
  el: NodeListOf<Element>

  constructor(selector: string) {
    this.el = document.querySelectorAll(selector)

    if (this.el.length) {
      this.initialize()
    }
  }

  initialize() {
    this.bind()
  }

  bind() {
    PubSub.subscribe('App.scroll', this.onScroll.bind(this))
  }

  async onScroll({ scrollY }: WindowState) {
    for (let el of this.el) {
      const isShrunk = scrollY > 1

      if (el instanceof HTMLElement) {
        el.dataset.shrunk = String(isShrunk)
      }
    }
  }
}
