import PubSub from 'vanilla-pubsub'
import type { WindowState } from '@/assets/js/project'
import { wait } from '../utils'

type MenuProps = {
  menuName: string
  trigger: HTMLElement | null
  content: HTMLElement | null
}

type MenuInstanceProps = {
  $menu: MenuProps
  [key: string]: any
}

export default class {
  selector: string
  menu: null | HTMLElement
  trigger: HTMLElement[]
  scrollBarWidth: number
  state: {
    isExpanded: boolean
    current: MenuProps
  }

  constructor(selector: string) {
    this.selector = selector
    this.menu = null
    this.scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth
    this.state = {
      isExpanded: false,
      current: {
        menuName: '',
        trigger: null,
        content: null,
      },
    }

    this.trigger = [...document.querySelectorAll(selector)] as HTMLElement[]

    if (this.trigger.length) {
      this.initialize()
    }
  }

  initialize() {
    this.updateState = this.updateState.bind(this)
    this.expand = this.expand.bind(this)
    this.close = this.close.bind(this)

    this.trigger.forEach((trigger) => {
      const menuName = trigger.dataset.menuName
      const content = document.querySelector(
        `.js-menu[data-menu-target="${menuName}"]`
      )

      if (!menuName) {
        console.error(
          `[Menu] Requires an element with ${this.selector}[data-menu-name="exampleMenu"]`
        )
        return
      }

      if (!content) {
        console.error(
          `[Menu] Requires an element with .js-menu[data-menu-target="${menuName}"]`
        )
        return
      }

      const closeTrigger = content.querySelector(
        '[data-menu-close]'
      ) as HTMLElement
      const $menu: MenuInstanceProps = {
        ...this,
        $menu: {
          menuName,
          trigger,
          content: content as HTMLElement,
        },
      }

      trigger.addEventListener('click', this.handleClick.bind($menu))
      if (closeTrigger) {
        closeTrigger.addEventListener('click', () =>
          this.close(this.state.current)
        )
      }
    })

    this.bind()
  }

  bind() {
    PubSub.subscribe('App.changeViewport', ({ isMobile }: WindowState) => {
      if (!isMobile && this.state.isExpanded) {
        this.close(this.state.current)
      }
    })
  }

  updateState(isExpanded: boolean, current: MenuProps) {
    this.state.isExpanded = isExpanded
    this.state.current = {
      ...current,
    }
  }

  async handleClick() {
    const props: any = { ...this }

    if (props.state.isExpanded) {
      await this.close(props.state.current)
      if (
        props.state.current.menuName &&
        props.state.current.menuName !== props.$menu.menuName
      ) {
        await this.expand(props.$menu)
      }
    } else {
      await this.expand(props.$menu)
    }
  }

  async expand(ctx: MenuProps) {
    const menuName = ctx.menuName

    document.documentElement.setAttribute(`data-menu-active`, menuName)
    ctx.content!.style.display = 'block'
    await wait(10)

    ctx.content!.setAttribute('aria-hidden', String(false))
    ctx.trigger!.setAttribute('aria-expanded', String(true))
    document.documentElement.setAttribute(`data-menu-ready`, menuName)

    document.documentElement.style.setProperty(
      '--scroll_bar_width',
      `${this.scrollBarWidth}px`
    )
    document.documentElement.style.overflow = 'hidden'

    this.state.isExpanded = true
    this.state.current.menuName = menuName
    this.state.current.trigger = ctx.trigger
    this.state.current.content = ctx.content

    this.updateState(true, ctx)
  }

  async close(ctx: MenuProps) {
    const durationTarget = document.querySelector(
      '[data-menu-duration-target]'
    )
    const duration = durationTarget
      ? parseFloat(getComputedStyle(durationTarget).transitionDuration) * 1000
      : 10

    document.documentElement.setAttribute('data-menu-remove-to', '')
    ctx.content!.setAttribute('aria-hidden', String(true))
    ctx.trigger!.setAttribute('aria-expanded', String(false))
    document.documentElement.removeAttribute('data-menu-ready')

    await wait(duration)
    document.documentElement.removeAttribute('data-menu-active')
    document.documentElement.removeAttribute('data-menu-remove-to')

    ctx.content!.style.display = ''

    document.documentElement.style.removeProperty('--scroll_bar_width')
    document.documentElement.style.overflow = ''

    this.updateState(false, {
      trigger: null,
      content: null,
      menuName: '',
    })
  }
}
